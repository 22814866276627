import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;
const username = import.meta.env.VITE_API_USERNAME;
const password = import.meta.env.VITE_API_PASSWORD;
const credentials = btoa(`${username}:${password}`);

// Instance axios préconfigurée
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Basic ${credentials}`,
    'Cache-Control': 'no-cache'
  }
});

export const postArtisan = async artisan => {
  try {
    const response = await apiClient.post('/api/artisan', artisan);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getArtisans = async () => {
  try {
    const response = await apiClient.get('/api/artisan/artisans');
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getArtisanById = async artisanId => {
  try {
    const response = await apiClient.get(`/api/artisan/${artisanId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getArtisansBySpecialiteId = async specialiteId => {
  try {
    const response = await apiClient.get(`/api/artisan/artisans-specialite/${specialiteId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const updateArtisan = async (artisanId, updatedArtisan) => {
  try {
    const response = await apiClient.put(`/api/artisan/${artisanId}`, updatedArtisan);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'artisan :", error);
    return null;
  }
};

export const deleteArtisan = async artisanId => {
  try {
    const response = await apiClient.delete(`/api/artisan/${artisanId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const postSpecialite = async metier => {
  try {
    const response = await apiClient.post('/api/specialite', metier);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getSpecialities = async () => {
  try {
    const response = await apiClient.get('/api/specialite');
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getSpecialityById = async specialityId => {
  try {
    const response = await apiClient.get(`/api/specialite/${specialityId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getSpecialityByArtisanId = async artisanId => {
  try {
    const response = await apiClient.get(`/api/specialite/specialites-artisan/${artisanId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const deleteSpecialite = async specialiteId => {
  try {
    // Inclure l'ID dans l'URL
    const response = await apiClient.delete(`/api/specialite/${specialiteId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la spécialité :', error);
    if (error.response && error.response.status === 400) {
      alert('Cette spécialité ne peut pas être supprimée car elle est utilisée par des artisans.');
    } else {
      alert('Une erreur est survenue lors de la suppression.');
    }
    return null;
  }
};

export const updateSpecialite = async (specialiteId, updatedSpecialite) => {
  try {
    const response = await apiClient.put(`/api/specialite/${specialiteId}`, updatedSpecialite);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la spécialité :', error);
    return null;
  }
};

export const postParticulier = async particulier => {
  try {
    const response = await apiClient.post('/api/particulier', particulier);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getParticulier = async () => {
  try {
    const response = await apiClient.get('/api/particulier');
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getParticulierById = async particulierId => {
  try {
    const response = await apiClient.get(`/api/particulier/${particulierId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const deleteParticulier = async particulierId => {
  try {
    // Inclure l'ID dans l'URL
    const response = await apiClient.delete(`/api/particulier/${particulierId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression du particulier :', error);
    if (error.response && error.response.status === 400) {
      alert('Ce particulier ne peut pas être supprimée.');
    } else {
      alert('Une erreur est survenue lors de la suppression.');
    }
    return null;
  }
};

export const updateParticulier = async (particulierId, updatedParticulier) => {
  try {
    const response = await apiClient.put(`/api/particulier/${particulierId}`, updatedParticulier);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du particulier :', error);
    return null;
  }
};

export const postNote = async note => {
  try {
    const response = await apiClient.post(`/api/note`, note);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getNote = async () => {
  try {
    const response = await apiClient.get('/api/note');
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getNotesByArtisanId = async artisanId => {
  try {
    const response = await apiClient.get(`/api/note/artisan/${artisanId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération de la note :', error);
    return null;
  }
};

export const deleteNote = async noteId => {
  try {
    // Inclure l'ID dans l'URL
    const response = await apiClient.delete(`/api/note/${noteId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la note :', error);
    if (error.response && error.response.status === 400) {
      alert('Cette note ne peut pas être supprimée.');
    } else {
      alert('Une erreur est survenue lors de la suppression.');
    }
    return null;
  }
};

export const updateNote = async (noteId, updatedNote) => {
  try {
    const response = await apiClient.put(`/api/note/${noteId}`, updatedNote);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la note :', error);
    return null;
  }
};

export const login = async user => {
  try {
    const response = await apiClient.post('/api/login', user);
    return response.data;
  } catch (error) {
    console.error('Login Failed:', error);
    return null;
  }
};

export const getUsers = async () => {
  try {
    const response = await apiClient.get('/api/user');
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const updateUser = async (userId, user) => {
  try {
    const response = await apiClient.put(`/api/user/${userId}`, user);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const postUser = async user => {
  try {
    const response = await apiClient.post('/api/user', user);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const deleteUser = async userId => {
  try {
    const response = await apiClient.delete(`/api/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const sendMail = async email => {
  try {
    const response = await apiClient.post('/api/artisan/email', email);
    return response.data;
  } catch (error) {
    console.error('Envoi de mail impossible :', error);
    return null;
  }
};

export const getCoordonnees = async addr => {
  try {
    const response = await apiClient.get(`/api/artisan/coordonnees/${addr}`);
    return response.data;
  } catch (error) {
    console.error('Adresse fournie invalide : ', error);
    return null;
  }
};

export const postDetailArtisan = async detail => {
  try {
    const response = await apiClient.post('/api/artisan/details', detail);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getDetailArtisanByArtisanId = async artisanId => {
  try {
    const response = await apiClient.get(`/api/artisan/details/artisan/${artisanId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const updateDetailArtisan = async (detailId, detail) => {
  try {
    const response = await apiClient.put(`/api/artisan/details/${detailId}`, detail);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const deleteDetailArtisan = async detailId => {
  try {
    const response = await apiClient.delete(`/api/artisan/details/${detailId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};

export const getDetailArtisanByArtisanIdAndType = async (artisanId, type) => {
  try {
    const response = await apiClient.get(`/api/artisan/details/type-artisan/${artisanId}`, {
      params: { type }
    });
    return response.data;
  } catch (error) {
    console.error('Erreur de réseau :', error);
    return null;
  }
};
