import React from 'react';
import styles from './QualifiedArtisansSection.module.css';

const QualifiedArtisansSection = () => {
  return (
    <section className={styles.qualifiedArtisansSection}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>DES ARTISANS VÉRIFIÉS ET QUALIFIÉS POUR TOUS VOS PROJETS</h2>
        <p className={styles.subtitle}>
          <span className={styles.expertText}>
            Notre réseau d’artisans est soigneusement sélectionné pour vous offrir la meilleure expertise. Chaque
            professionnel est évalué et certifié, assurant ainsi un travail de qualité et une satisfaction maximale.
          </span>
        </p>
        <div className={styles.textBlock}>
          <div className={styles.textPart}>
            <p className={styles.text}>
              Nos artisans sont disponibles pour vos projets de construction, rénovation, aménagement intérieur, et plus
              encore.
            </p>
          </div>
          <div className={styles.textPart}>
            <p className={styles.text}>
              Choisissez parmi une variété de corps de métiers, adaptés à vos besoins spécifiques.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src="/artisans-image1.png" alt="Artisan" className={styles.artisanImage} />
      </div>
    </section>
  );
};

export default QualifiedArtisansSection;
