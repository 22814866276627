import React from 'react';
import ArtisansCard from '../components/ArtisansCard/ArtisansCard';
import ContactPageForm from '../components/ContactPageForm/ContactForm'; // Importer le formulaire de contact
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import IntroductionSection from '../components/IntroductionSection/IntroductionSection';
import QualifiedArtisansSection from '../components/QualifiedArtisansSection/QualifiedArtisansSection';
import ServicesSection from '../components/ServicesSection/ServicesSection'; // Importer le nouveau composant

const HomePage = () => {
  return (
    <div>
      <Header />
      <IntroductionSection />
      {/* Ajoute d'autres sections pour la page d'accueil ici si nécessaire */}
      <ArtisansCard /> {/* Intègre le composant ArtisanCard ici */}
      <QualifiedArtisansSection /> {/* Intègre le nouveau composant ici */}
      <ServicesSection />
      <ContactPageForm /> {/* Utilise ContactPageForm ici pour afficher le formulaire de contact */}
      <Footer />
    </div>
  );
};

export default HomePage;
