import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './IntroductionSection.module.css';

const IntroductionSection = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSecteurSubmit = event => {
    event.preventDefault();
    const path = searchTerm ? `/map?search=${encodeURIComponent(searchTerm)}` : '/map';
    navigate(path);
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <section className={styles.introSection}>
      <div className={styles.textContainer}>
        <h2 className={styles.introText}>
          TROUVEZ UN ARTISAN DE CONFIANCE PRÈS DE CHEZ VOUS DÉCOUVREZ LES ARTISANS QUALIFIÉS POUR TOUS VOS PROJETS DE
          CONSTRUCTION, RÉNOVATION, ET AMÉNAGEMENT.
        </h2>
        <div className={styles.buttonContainer}>
          <form className={styles.searchForm}>
            <input type="text" placeholder="Recherche par corps de métier" className={styles.searchInput} />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
          <form className={styles.searchForm} onSubmit={handleSecteurSubmit}>
            <input
              type="text"
              placeholder="Recherche par secteur géographique"
              className={styles.searchInput}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          <div>
            <img src="/artisans-image1.png" alt="Artisan 1" className={styles.carouselImage} />
          </div>
          <div>
            <img src="/artisans-image2.png" alt="Artisan 2" className={styles.carouselImage} />
          </div>
          <div>
            <img src="/artisans-image3.png" alt="Artisan 3" className={styles.carouselImage} />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default IntroductionSection;
