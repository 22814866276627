import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { deleteNote, getNotesByArtisanId, getParticulierById } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const ModalNotesArtisan = ({ artisan, isOpen, onClose }) => {
  const [notes, setNotes] = useState([]);
  const [particuliers, setParticuliers] = useState({});

  useEffect(() => {
    if (artisan && isOpen) {
      // Récupérer toutes les notes via getNotesByArtisanId
      const fetchNotes = async () => {
        const fetchedNotes = await getNotesByArtisanId(artisan.id);
        setNotes(fetchedNotes);

        // Récupérer les détails des particuliers associés aux notes
        const fetchedParticuliers = {};
        await Promise.all(
          fetchedNotes.map(async note => {
            if (!fetchedParticuliers[note.particulierId]) {
              const particulier = await getParticulierById(note.particulierId);
              fetchedParticuliers[note.particulierId] = particulier;
            }
          })
        );
        setParticuliers(fetchedParticuliers);
      };
      fetchNotes();
    }
  }, [artisan, isOpen]);

  const deleteNoteById = async noteId => {
    await deleteNote(noteId);
    setNotes(notes.filter(note => note.id !== noteId));
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2>
          Notes pour {artisan.prenom} {artisan.nom}
        </h2>
        <button onClick={onClose} className={styles.closeButton}>
          Fermer
        </button>

        <ul>
          {notes.map(note => (
            <li key={note.id} className={styles.noteItem}>
              <p>
                {particuliers[note.particulierId] &&
                  `${particuliers[note.particulierId].prenom} ${particuliers[note.particulierId].nom}`}
              </p>
              <p>{note.commentaire}</p>
              <p>{'⭐'.repeat(note.nombreEtoiles)}</p>
              <button
                onClick={() => deleteNoteById(note.id)}
                className={styles.deleteNoteButton}
                aria-label={'Supprimer la note'}
              >
                ✖️
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ModalNotesArtisan.propTypes = {
  artisan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nom: PropTypes.string.isRequired,
    prenom: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalNotesArtisan;
