import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Metier from '../components/Metier/Metier';

const MetierPage = () => {
  const { specialiteId } = useParams();

  return (
    <div>
      <Header />
      <Metier specialiteId={specialiteId} />
      <Footer />
    </div>
  );
};

export default MetierPage;
