import { getDistance } from 'geolib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getArtisansBySpecialiteId,
  getCoordonnees,
  getDetailArtisanByArtisanIdAndType,
  getSpecialityById
} from '../../services/Api';
import styles from './Metier.module.css';

const Metier = ({ specialiteId }) => {
  const [artisans, setArtisans] = useState([]);
  const [specialite, setSpecialite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tri, setTri] = useState('pertinence');
  const [userLocation, setUserLocation] = useState(null);
  const [adresse, setAdresse] = useState('');

  useEffect(() => {
    // Fonction pour récupérer les artisans et la localisation de l'utilisateur
    const initializeData = async () => {
      // Récupérer la localisation de l'utilisateur
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        });
      } else {
        console.log("La géolocalisation n'est pas supportée par ce navigateur.");
      }

      // Récupérer les artisans de la spécialité
      const response = await getArtisansBySpecialiteId(specialiteId);
      const artisansData = response || [];

      // Ajouter les images principales pour chaque artisan
      const artisansWithImages = await Promise.all(
        artisansData.map(async artisan => {
          const image_principale = await getDetailArtisanByArtisanIdAndType(artisan.id, 'IMG_PRINCIPALE');
          return { ...artisan, image_principale };
        })
      );

      const spe = await getSpecialityById(specialiteId);

      setArtisans(artisansWithImages);
      setSpecialite(spe);
      setLoading(false);
    };

    initializeData();
  }, [specialiteId]);

  // Fonction pour rechercher les coordonnées de l'adresse saisie
  const handleAdresseChange = async () => {
    try {
      const coords = await getCoordonnees(adresse);
      setUserLocation({
        latitude: coords.lat,
        longitude: coords.lon
      });
    } catch (error) {
      console.log('Adresse non trouvée. Veuillez essayer une autre adresse. ', error);
    }
  };

  // Fonction pour trier les artisans en fonction de la localisation
  const trierArtisans = () => {
    if (tri === 'localisation' && userLocation) {
      return artisans.sort((a, b) => {
        const distanceA = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: a.latitude, longitude: a.longitude }
        );
        const distanceB = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: b.latitude, longitude: b.longitude }
        );
        return distanceA - distanceB; // Tri par distance croissante
      });
    }
    return artisans; // Par défaut, trier par pertinence (pas de tri appliqué ici)
  };

  if (loading) {
    return <p>Chargement des artisans...</p>;
  }

  if (artisans.length === 0) {
    return <p>Aucun artisan trouvé pour cette spécialité.</p>;
  }

  return (
    <div className={styles.metierContainer}>
      <h2 className={styles.title}>{specialite.metier}</h2>
      {/* Sélection du tri */}
      <div className={styles.triContainer}>
        <div className={styles.triSelectContainer}>
          <label htmlFor="tri">Trier par:</label>
          <select id="tri" value={tri} onChange={e => setTri(e.target.value)}>
            <option value="pertinence">Pertinence</option>
            <option value="localisation">Localisation</option>
          </select>
        </div>

        <Link to="/map">
          <button className={styles.mapButton}>Voir sur la carte</button>
        </Link>
      </div>

      {/* Champ de saisie pour l'adresse */}
      {tri === 'localisation' && (
        <div className={styles.adresseContainer}>
          <label htmlFor="adresse">Code postal ou ville :</label>
          <input type="text" id="adresse" value={adresse} onChange={e => setAdresse(e.target.value)} />
          <button onClick={handleAdresseChange}>Rechercher</button>
        </div>
      )}

      <ul className={styles.artisanList}>
        {trierArtisans().map(artisan => (
          <li key={artisan.id} className={styles.artisanCard}>
            <div className={styles.artisanImage}>
              <Link to={`/artisan/${artisan.id}`}>
                <img
                  src={`data:${artisan.image_principale.mimeType};base64,${artisan.image_principale.imageDetail}`}
                  alt={artisan.image_principale.imageDetail}
                />
              </Link>
            </div>

            {/* Affiche les informations de l'artisan */}
            <div className={styles.artisanInfo}>
              <p className={styles.artisanName}>
                {artisan.nom} {artisan.prenom}
              </p>
              <p>Ville : {artisan.adresse.ville}</p>
              <p>Rayon d&apos;activité : {artisan.rayonActivite} km</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

Metier.propTypes = {
  specialiteId: PropTypes.string.isRequired
};

export default Metier;
