import React from 'react';
import styles from './ServicesSection.module.css';

const ServicesSection = () => {
  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.title}>NOS SERVICES : UN ACCOMPAGNEMENT DE A À Z</h2>
      <p className={styles.subtitle}>
        Que vous soyez particulier ou professionnel, notre plateforme vous accompagne dans chaque étape de votre projet.
        Du choix de l’artisan à la réalisation des travaux, nous mettons à votre disposition des outils et des conseils
        pour garantir le succès de vos projets.
      </p>
      <div className={styles.serviceCards}>
        <div className={`${styles.card} ${styles.cardQuality}`}>
          <h3 className={styles.cardTitle}>QUALITÉ ET EXPERTISE</h3>
        </div>
        <div className={`${styles.card} ${styles.cardSatisfaction}`}>
          <h3 className={styles.cardTitle}>SATISFACTION CLIENT</h3>
        </div>
        <div className={`${styles.card} ${styles.cardServices}`}>
          <h3 className={styles.cardTitle}>SERVICES RAPIDES ET EFFICACES</h3>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
