import React from 'react';
import styles from './ArtisansCard.module.css';

const ArtisansCard = () => {
  return (
    <section className={styles.artisansCard}>
      <h2 className={styles.title}>FAITES CONFIANCE À DES VÉRIFIÉS ET QUALIFIÉS</h2>
      <p className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service qualité.
      </p>
      <div className={styles.artisanGallery}>
        <div className={styles.artisanImage}>
          <img src="/artisans-image1.png" alt="Artisan 1" />
          <div className={styles.metierText}>PLOMBIER</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-image2.png" alt="Artisan 2" />
          <div className={styles.metierText}>MENUISIER</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-image3.png" alt="Artisan 3" />
          <div className={styles.metierText}>ÉLECTRICIEN</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-image3.png" alt="Artisan 4" />
          <div className={styles.metierText}>CARRELEUR</div>
        </div>
      </div>
      <button className={styles.discoverButton}>Découvrir nos artisans</button>
    </section>
  );
};

export default ArtisansCard;
