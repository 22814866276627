import React from 'react';
import styles from './Propos.module.css';
import { Link } from 'react-router-dom';

const Propos = () => {
  return (
    <section className={styles.proposSection}>
      <h1 className={styles.title}>À propos de ProxiTravaux</h1>

      <div className={styles.missionSection}>
        <h2 className={styles.subtitle}>Notre mission</h2>
        <p className={styles.text}>
          La mission de ProxiTravaux est de faciliter l&apos;accès aux artisans locaux de confiance pour tous les
          particuliers qui souhaitent réaliser des travaux de qualité.
        </p>
      </div>

      <div className={styles.valuesSection}>
        <h2 className={styles.subtitle}>Nos valeurs</h2>
        <p className={styles.text}>
          Chez ProxiTravaux, nous mettons en avant quatre valeurs fondamentales : Qualité, Transparence, Proximité et
          Respect.
        </p>
      </div>

      <div className={styles.historySection}>
        <h2 className={styles.subtitle}>Un peu d&apos;histoire</h2>
        <p className={styles.text}>
          Fondée en 2025, ProxiTravaux est née du besoin de simplifier l&apos;accès aux artisans de confiance pour les
          particuliers.
        </p>
      </div>

      <div className={styles.expertiseSection}>
        <h2 className={styles.subtitle}>Notre expertise</h2>
        <p className={styles.text}>
          Nous mettons en relation des artisans qualifiés spécialisés dans la rénovation, la construction durable, et
          l&apos;aménagement intérieur.
        </p>
      </div>

      <div className={styles.teamSection}>
        <h2 className={styles.subtitle}>Notre équipe</h2>
        <p className={styles.text}>
          L&apos;équipe de ProxiTravaux est composée de spécialistes du digital, de la relation client et du bâtiment.
        </p>
      </div>

      <div className={styles.contactSection}>
        <h2 className={styles.subtitle}>Contactez-nous</h2>
        <p className={styles.text}>
          ProxiTravaux est basé à Brive. Pour toute question, vous pouvez nous joindre par email, téléphone, ou sur nos
          réseaux sociaux.
        </p>
      </div>

      <div className={styles.joinSection}>
        <Link to="/join" className={styles.joinButton}>
          Nous Rejoindre
        </Link>
      </div>
    </section>
  );
};

export default Propos;
