import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getArtisans, getParticulier, postNote } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

import { toast } from 'react-toastify';
import AddParticulierForm from './AddParticulierForm';

const ListParticulier = () => {
  const [particuliers, setParticuliers] = useState([]);
  const [artisans, setArtisans] = useState([]);
  const [selectedArtisan, setSelectedArtisan] = useState('');
  const [note, setNote] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [selectedParticulier, setSelectedParticulier] = useState(null);

  // Chargement des données des particuliers et artisans lors du montage du composant
  useEffect(() => {
    const fetchParticuliers = async () => {
      const data = await getParticulier();
      setParticuliers(data);
    };

    const fetchArtisans = async () => {
      const data = await getArtisans();
      setArtisans(data);
    };

    fetchParticuliers();
    fetchArtisans();
  }, []);

  // Soumission de la note
  const handleNoteSubmit = async e => {
    e.preventDefault();
    if (selectedParticulier && selectedArtisan && note) {
      const newNote = {
        particulierId: selectedParticulier,
        artisanId: selectedArtisan,
        commentaire,
        nombreEtoiles: parseInt(note)
      };
      await postNote(newNote);
      toast.success('Note envoyée avec succès !');
      resetForm();
    }
  };

  // Réinitialiser le formulaire de notation
  const resetForm = () => {
    setSelectedParticulier(null);
    setSelectedArtisan('');
    setNote('');
    setCommentaire('');
    setShowNoteForm(false);
  };

  return (
    <div className={styles.backofficeContainer}>
      <h1>Gestion des Particuliers</h1>

      {/* Bouton pour revenir à la page d'accueil */}
      <Link to="/backoffice/admin/home">
        <button className={styles.buttonList}>Retour à l&apos;accueil</button>
      </Link>

      <ul className={styles.particulierList}>
        {particuliers.map(particulier => (
          <li key={particulier.id} className={styles.particulierItem}>
            {particulier.nom} {particulier.prenom}
            <button
              className={styles.buttonNote}
              onClick={() => {
                setSelectedParticulier(particulier.id);
                setShowNoteForm(true);
              }}
            >
              Noter un artisan
            </button>
          </li>
        ))}
      </ul>

      {/* Formulaire pour ajouter un particulier */}
      <AddParticulierForm onSubmit={setParticuliers} />

      {showNoteForm && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h2>Noter un Artisan</h2>
            <form onSubmit={handleNoteSubmit}>
              <label>
                Artisan:
                <select onChange={e => setSelectedArtisan(e.target.value)} value={selectedArtisan} required>
                  <option value="">Sélectionner un artisan</option>
                  {artisans.map(artisan => (
                    <option key={artisan.id} value={artisan.id}>
                      {artisan.nom} {artisan.prenom}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Note:
                <input type="number" min="1" max="5" value={note} onChange={e => setNote(e.target.value)} required />
              </label>
              <label>
                Commentaire:
                <textarea value={commentaire} onChange={e => setCommentaire(e.target.value)} />
              </label>
              <div className={styles.popupButtons}>
                <button className={styles.buttonList} type="submit">
                  Envoyer la Note
                </button>
                <button className={styles.buttonList} type="button" onClick={resetForm}>
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListParticulier;
