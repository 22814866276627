import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getSpecialities, getUsers } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const AddArtisanForm = ({ onSubmit, existingArtisan }) => {
  const [formData, setFormData] = useState({
    adresse: {
      rue: '',
      ville: '',
      codePostal: '',
      pays: ''
    },
    age: '',
    anneeExperience: '',
    prenom: '',
    nom: '',
    entrepriseNom: '',
    rayonActivite: '',
    latitude: 0,
    longitude: 0,
    siret: '',
    siren: '',
    userId: 0,
    specialites: [],
    notes: [],
    details: []
  });

  const [specialites, setSpecialites] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchSpecialities = async () => {
      const data = await getSpecialities();
      setSpecialites(data);
    };

    const fetchUsers = async () => {
      const allUsers = await getUsers();
      const artisans = allUsers.filter(user => user.role === 'ROLE_ARTISAN');
      setUsers(artisans);
    };

    fetchSpecialities();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (existingArtisan) {
      setFormData({
        id: existingArtisan.id,
        adresse: {
          rue: existingArtisan.adresse.rue,
          ville: existingArtisan.adresse.ville,
          codePostal: existingArtisan.adresse.codePostal,
          pays: existingArtisan.adresse.pays
        },
        age: existingArtisan.age,
        anneeExperience: existingArtisan.anneeExperience,
        prenom: existingArtisan.prenom,
        nom: existingArtisan.nom,
        entrepriseNom: existingArtisan.entrepriseNom,
        rayonActivite: existingArtisan.rayonActivite,
        latitude: existingArtisan.latitude,
        longitude: existingArtisan.longitude,
        siret: existingArtisan.siret,
        siren: existingArtisan.siren,
        userId: existingArtisan.userId,
        specialites: existingArtisan.specialites,
        notes: existingArtisan.notes,
        details: existingArtisan.details
      });
    }
  }, [existingArtisan]);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name.startsWith('adresse.')) {
      const key = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        adresse: {
          ...prevData.adresse,
          [key]: value
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSpecialityChange = e => {
    const { value, checked } = e.target;
    const id = Number(value);

    setFormData(prevData => ({
      ...prevData,
      specialites: checked
        ? [...prevData.specialites, id]
        : prevData.specialites.filter(specialiteId => specialiteId !== id)
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(formData); // Envoyer les données au parent (ListArtisan)
  };

  return (
    <form onSubmit={handleSubmit} className={styles.artisanForm}>
      <div className={styles.formGroup}>
        <label>Artisan :</label>
        <select name="userId" value={formData.userId || ''} onChange={handleChange} required>
          <option value="" disabled>
            Sélectionnez un utilisateur
          </option>
          {users.map(user => (
            <option key={user.id} value={user.id}>
              {user.username} (email : {user.email})
            </option>
          ))}
        </select>
      </div>

      <fieldset className={styles.formSection}>
        <legend className={styles.formLegend}>Adresse</legend>
        <div className={styles.formGroup}>
          <label>Rue:</label>
          <input type="text" name="adresse.rue" value={formData.adresse.rue} onChange={handleChange} required />
        </div>
        <div className={styles.formGroup}>
          <label>Ville:</label>
          <input type="text" name="adresse.ville" value={formData.adresse.ville} onChange={handleChange} required />
        </div>
        <div className={styles.formGroup}>
          <label>Code Postal:</label>
          <input
            type="text"
            name="adresse.codePostal"
            value={formData.adresse.codePostal}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label>Pays:</label>
          <input type="text" name="adresse.pays" value={formData.adresse.pays} onChange={handleChange} required />
        </div>
      </fieldset>

      <div className={styles.formGroup}>
        <label>Âge:</label>
        <input type="number" name="age" value={formData.age} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>Années d&apos;expérience:</label>
        <input type="number" name="anneeExperience" value={formData.anneeExperience} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>Prénom:</label>
        <input type="text" name="prenom" value={formData.prenom} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>Nom:</label>
        <input type="text" name="nom" value={formData.nom} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>Nom de société:</label>
        <input type="text" name="entrepriseNom" value={formData.entrepriseNom} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>Rayon d&apos;activité:</label>
        <input type="text" name="rayonActivite" value={formData.rayonActivite} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>SIRET:</label>
        <input type="text" name="siret" value={formData.siret} onChange={handleChange} required />
      </div>

      <div className={styles.formGroup}>
        <label>SIREN:</label>
        <input type="text" name="siren" value={formData.siren} onChange={handleChange} required />
      </div>

      <fieldset className={styles.formSection}>
        <legend className={styles.formLegend}>Spécialités</legend>
        {specialites.map(specialite => (
          <div key={specialite.id} className={styles.formCheckboxGroup}>
            <input
              type="checkbox"
              id={`specialite-${specialite.id}`}
              value={specialite.id}
              checked={formData.specialites.includes(specialite.id)}
              onChange={handleSpecialityChange}
            />
            <label htmlFor={`specialite-${specialite.id}`}>{specialite.metier}</label>
          </div>
        ))}
      </fieldset>

      <button type="submit" className={styles.submitButton}>
        {formData.id ? 'Mettre à jour' : 'Ajouter'}
      </button>
    </form>
  );
};

AddArtisanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  existingArtisan: PropTypes.shape({
    id: PropTypes.number,
    adresse: PropTypes.shape({
      rue: PropTypes.string,
      ville: PropTypes.string,
      codePostal: PropTypes.string,
      pays: PropTypes.string
    }),
    age: PropTypes.string,
    anneeExperience: PropTypes.string,
    prenom: PropTypes.string,
    nom: PropTypes.string,
    entrepriseNom: PropTypes.string,
    rayonActivite: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    siret: PropTypes.string,
    siren: PropTypes.string,
    userId: PropTypes.number,
    specialites: PropTypes.arrayOf(PropTypes.number),
    notes: PropTypes.arrayOf(PropTypes.number),
    details: PropTypes.arrayOf(PropTypes.number)
  })
};

export default AddArtisanForm;
