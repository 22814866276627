import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HomeBackoffice.module.css';

const HomeBackoffice = () => {
  return (
    <div className={styles.containerBackoffice}>
      <h1>Bienvenue dans le Backoffice</h1>
      <p>Gérez les artisans, spécialités et particuliers en toute simplicité.</p>

      <Link to="/backoffice/admin/artisans">
        <button className={styles.buttonBackoffice}>Gestion des artisans</button>
      </Link>

      <Link to="/backoffice/admin/specialites">
        <button className={styles.buttonBackoffice}>Gestion des spécialités</button>
      </Link>

      <Link to="/backoffice/admin/particuliers">
        <button className={styles.buttonBackoffice}>Gestion des particuliers</button>
      </Link>

      <Link to="/backoffice/admin/accounts">
        <button className={styles.buttonBackoffice}>Gestion des comptes</button>
      </Link>
    </div>
  );
};

export default HomeBackoffice;
