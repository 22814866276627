import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMail } from '../../services/Api';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  // États pour stocker les valeurs du formulaire
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });

  // Gestion des changements dans le formulaire
  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  // Gestionnaire pour la soumission du formulaire
  const handleSubmit = async e => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire

    // Crée un objet EmailDto à partir des valeurs du formulaire
    const emailDto = {
      nom: formValues.name,
      email: formValues.email,
      tel: formValues.phone,
      entrepriseName: formValues.company,
      message: formValues.message
    };

    await sendMail(emailDto);

    // Message de confirmation
    toast.success('Message envoyé avec succès !');

    // Réinitialise les champs du formulaire
    setFormValues({
      name: '',
      email: '',
      phone: '',
      company: '',
      message: ''
    });
  };

  return (
    <section className={styles.contactSection}>
      {' '}
      {/* Conteneur global ajouté ici */}
      <div className={styles.textContainer}>
        <h1 className={styles.title}>PROXI TRAVAUX</h1>

        <p>
          Besoin de plus d’informations ? Remplissez le formulaire ci-contre pour contacter notre équipe. Nous sommes à
          votre disposition pour répondre à toutes vos questions et pour vous aider à trouver l’artisan parfait pour
          votre projet.
        </p>
      </div>
      <div className={styles.formContainer}>
        <h2 className={styles.formTitle}>CONTACTEZ NOUS</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            type="text"
            id="name"
            placeholder="Votre nom"
            data-testid="name-input"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            className={styles.input}
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            data-testid="email-input"
            placeholder="Votre email"
            value={formValues.email}
            onChange={handleInputChange}
            className={styles.input}
            required
          />

          <input
            type="tel"
            id="phone"
            name="phone"
            data-testid="tel-input"
            placeholder="Votre téléphone"
            value={formValues.phone}
            onChange={handleInputChange}
            className={styles.input}
            required
          />

          <textarea
            id="message"
            name="message"
            placeholder="Votre message"
            data-testid="mess-input"
            value={formValues.message}
            onChange={handleInputChange}
            className={styles.input}
            required
          />

          <button type="submit" className={styles.submitButton}>
            ENVOYER LE FORMULAIRE
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
