import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './HomeBackoffice.module.css';

const AddUserForm = ({ onSubmit, user }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('ADMIN'); // Valeur par défaut
  const [showPassword, setShowPassword] = useState(false);

  // Pré-remplir le formulaire si un utilisateur est passé en props (modification)
  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUsername(user.username);
      setPassword(user.password);
      setRole(user.role);
    } else {
      setEmail('');
      setUsername('');
      setPassword('');
      setRole('ROLE_ADMIN');
    }
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ email, username, password, role });
    setEmail('');
    setUsername('');
    setPassword('');
    setRole('ROLE_ADMIN');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email :</label>
        <input type="text" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="username">Nom d&apos;utilisateur :</label>
        <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="password">Mot de passe :</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <span onClick={() => setShowPassword(prev => !prev)} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {showPassword ? '👁️' : '🙈'}
          </span>
        </div>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="role">Rôle :</label>
        <select id="role" value={role} onChange={e => setRole(e.target.value)} required>
          <option value="ROLE_ADMIN">Admin</option>
          <option value="ROLE_ARTISAN">Artisan</option>
          <option value="ROLE_PARTICULIER">Particulier</option>
        </select>
      </div>
      <button className={styles.buttonList} type="submit">
        {user ? 'Modifier' : 'Ajouter'} l&apos;utilisateur
      </button>
    </form>
  );
};

AddUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    role: PropTypes.string
  })
};

export default AddUserForm;
