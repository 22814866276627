import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSpecialities } from '../../services/Api';
import styles from './Header.module.css';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Etat pour gérer le menu burger
  const [specialities, setSpecialities] = useState([]);
  const [filteredSpecialities, setFilteredSpecialities] = useState([]);

  useEffect(() => {
    const fetchSpecialities = async () => {
      const response = await getSpecialities();
      setSpecialities(response);
      setFilteredSpecialities(response);
    };

    fetchSpecialities();
  }, []);

  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle du menu burger

  // Fonction pour fermer le menu burger lorsqu'un lien est cliqué
  const handleLinkClick = () => {
    setIsMobileMenuOpen(false); // Ferme le menu burger
  };

  const handleSearch = e => {
    const term = e.target.value.toLowerCase();
    setFilteredSpecialities(
      term ? specialities.filter(speciality => speciality.metier.toLowerCase().includes(term)) : specialities
    );
  };

  return (
    <header className={styles.header}>
      {/* Lien vers la page d'accueil (Home) au clic sur le logo */}
      <div className={styles.logo}>
        <Link to="/" onClick={handleLinkClick}>
          <img src="/logo.png" alt="Logo" className={styles.logoImage} />
        </Link>
      </div>

      {/* Menu classique visible sur grands écrans */}
      <nav className={`${styles.nav} ${isMobileMenuOpen ? styles.open : ''}`}>
        <Link to="/about" data-testid="a-propos" className={styles.navLink} onClick={handleLinkClick}>
          À PROPOS
        </Link>
        <div className={styles.dropdownContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Link to="/metiers" className={styles.navLink} onClick={handleLinkClick}>
            Nos artisans
          </Link>
          {isDropdownOpen && (
            <div className={styles.dropdownMenu}>
              <input
                type="text"
                placeholder="Rechercher une spécialité..."
                className={styles.searchInput}
                onChange={handleSearch}
              />
              {filteredSpecialities.map(speciality => (
                <Link
                  key={speciality.id}
                  to={`/metier/${speciality.id}`}
                  className={styles.dropdownItem}
                  onClick={handleLinkClick}
                >
                  {speciality.metier}
                </Link>
              ))}
            </div>
          )}
        </div>
        <Link to="/near-me" className={styles.navLink} onClick={handleLinkClick}>
          PRÈS DE CHEZ MOI
        </Link>
        <Link to="/contact" className={styles.navLink} onClick={handleLinkClick}>
          CONTACT
        </Link>
      </nav>

      {/* Bouton "Nous rejoindre" visible dans la navigation principale sur desktop */}
      <Link to="/join" data-testid="nous-rejoindre" className={styles.joinButton} onClick={handleLinkClick}>
        NOUS REJOINDRE
      </Link>

      {/* Menu burger */}
      <div className={styles.menuIcon} onClick={toggleMobileMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Menu mobile */}
      <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ''}`}>
        <Link to="/about" className={styles.navLink} onClick={handleLinkClick}>
          À PROPOS
        </Link>
        <Link to="/metiers" className={styles.navLink} onClick={handleLinkClick}>
          Nos artisans
        </Link>
        <Link to="/near-me" className={styles.navLink} onClick={handleLinkClick}>
          PRÈS DE CHEZ MOI
        </Link>
        <Link to="/contact" className={styles.navLink} onClick={handleLinkClick}>
          CONTACT
        </Link>
        <Link to="/join" className={styles.joinButton} onClick={handleLinkClick}>
          NOUS REJOINDRE
        </Link>{' '}
        {/* Affiché uniquement dans le menu burger */}
      </div>
    </header>
  );
};

export default Header;
